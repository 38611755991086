import apiFetch from '@wordpress/api-fetch';

apiFetch.use((options, next) => {
  const { startLoading } = useLoadingState();

  const key = options.url || Date.now().toString();
  const stopLoading = startLoading(key);
  const result = next({
    ...options,
    parse: false,
  })
    .then(async (response) => {
      const total = response?.headers?.get('X-WP-Total');
      const data = await response.json();
      return { data, total };
    })
    .catch(async (error) => {
      if (typeof error.json !== 'function') {
        throw createError({
          data: error,
          status: error.status,
          message: error.message,
        });
      }
      const e = await error.json();
      throw createError({
        data: e,
        status: e.status,
        message: e.message,
      });
    })
    .finally(() => {
      stopLoading();
    });
  return result;
});

export const fetcher = <T>(
  path: string,
  options?: Omit<Parameters<typeof apiFetch>[0], 'url'>,
) => {
  const {
    public: { wpApiBaseUrl },
  } = useRuntimeConfig();
  const { token } = useAuth();

  return apiFetch<{ data: T; total?: number }>({
    url: `${wpApiBaseUrl}${path}`,
    ...options,
    headers: token.value
      ? {
          ['Authorization']: `${token.value}`,
          ...options?.headers,
        }
      : options?.headers,
  });
};
